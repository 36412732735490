var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-column justify-center height-100"},[_c('CyDataTableYdApi',_vm._b({ref:"dataTable",staticClass:"inventory-table",attrs:{"id":"cy-inventory-table","fetch-available":{ keyPath: 'inventoryResources' },"row-click-callback":_vm.openSidePanel,"working-route":_vm.workingRoute,"searchable-fields":_vm.$static.searchableFields,"custom-search-placeholder":_vm.$t('inventory.filterByKeyword'),"elevation":"0","key-field":"id","data-cy":"table"},scopedSlots:_vm._u([{key:"table-cmp-header-filters",fn:function(){return [_c('div',{staticClass:"mx-4"},[_c('CyFilterTree',{attrs:{"filter-tree":_vm.filters,"value":_vm.selectedFilters},on:{"input":_vm.setFilterValues}})],1)]},proxy:true},{key:"table-cmp-header-actions",fn:function(ref){
var paginate = ref.paginate;
var filteredItemsTableData = ref.filteredItemsTableData;
return [(paginate && filteredItemsTableData.length)?_c('CyDataTablePagination',{staticClass:"top-pagination",attrs:{"items-length":filteredItemsTableData.length,"options":_vm.dataTableProps,"is-header":"","data-cy":"top-pagination"},on:{"update:options":function($event){_vm.dataTableProps=$event}}}):_vm._e(),(_vm.quotasEnabled)?_c('CyButton',{directives:[{name:"has-rights-to",rawName:"v-has-rights-to",value:('CreateInventoryResource'),expression:"'CreateInventoryResource'"}],staticClass:"ml-4",attrs:{"icon":"add","theme":"secondary","variant":"primary","to":{
          name: 'newResource',
          params: { backRouteTo: 'inventory' },
        },"data-cy":"create-resource-button"}},[_vm._v(" "+_vm._s(_vm.$t('inventory.addResource'))+" ")]):_vm._e()]}},{key:"cy-table-cmp-header-tag",fn:function(ref){
        var tag = ref.tag;
        var remove = ref.remove;
return [(_vm._.values(_vm.$static.FILTER_KEY_MATCHES).includes(tag.key.split('[')[0]))?void 0:[_c('CyTag',{staticClass:"ma-1",attrs:{"tall":"","variant":"warning"}},[_c('v-icon',{staticClass:"mr-1",attrs:{"size":"20","color":"warning darken-3"}},[_vm._v(" data_object ")]),_c('b',{staticClass:"mr-2 warning--text text--darken-3"},[_vm._v(" "+_vm._s(tag.key.split('[')[0])+": ")]),_c('span',{staticClass:"warning--text text--darken-3"},[_vm._v(" "+_vm._s(tag.value)+" ")]),_c('a',{on:{"click":function($event){return remove(tag.key, tag.id)}}},[_c('v-icon',{attrs:{"color":"warning darken-3","size":"16"}},[_vm._v(" close ")])],1)],1)]]}},{key:"table-cmp-body-row",fn:function(ref){
        var item = ref.props.item;
return [_c('td',{key:"name"},[_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"line-height-xs"},[_c('CyInventoryResourceIcon',{staticClass:"mr-2",attrs:{"image-src":_vm.getImage(item),"is-custom":_vm.isCustomResource(item),"data-cy":"item-icon","size":"32"}})],1),_c('div',[_c('div',{staticClass:"resource-row__name"},[_c('CyTooltip',{attrs:{"open-delay":"250","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(" "+_vm._s(item.name)+" ")])]}}],null,true)},[_vm._v(" "+_vm._s(item.name)+" ")])],1),_c('div',{staticClass:"resource-row__id"},[_c('CyTooltip',{attrs:{"open-delay":"250","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(" "+_vm._s(_vm.getId(item))+" ")])]}}],null,true)},[_vm._v(" "+_vm._s(_vm.getId(item))+" ")])],1)])])]),_c('td',{key:"provider",attrs:{"title":_vm.getProviderTranslation(item, 'name'),"data-cy":"item-provider"},domProps:{"textContent":_vm._s(_vm.getProviderTranslation(item) || _vm.getProvider(item))}}),_c('td',{key:"category"},[_c('CyTag',{attrs:{"variant":"default","data-cy":"item-category"}},[_vm._v(" "+_vm._s(item.category)+" ")])],1),_c('td',{key:"type",attrs:{"data-cy":"item-type"}},[_c('div',{staticClass:"resource-row__type"},[_c('CyTooltip',{attrs:{"open-delay":"250","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('span',_vm._g({domProps:{"textContent":_vm._s(item.type)}},on))]}}],null,true)},[_vm._v(" "+_vm._s(item.type)+" ")])],1)]),_c('td',{key:"project",attrs:{"data-cy":"item-project"},domProps:{"textContent":_vm._s(item.project_canonical)}}),_c('td',{key:"environment",attrs:{"data-cy":"item-environment"},domProps:{"textContent":_vm._s(item.environment_canonical)}})]}},{key:"table-cmp-no-data",fn:function(){return [_c('div',{staticClass:"empty-state text-center"},[_c('div',{staticClass:"empty-state__heading h5",attrs:{"data-cy":"empty-state-heading"},domProps:{"textContent":_vm._s(_vm.$t('noResourcesFound'))}}),_c('div',{staticClass:"text-body mt-2",attrs:{"data-cy":"empty-state-subheading"},domProps:{"textContent":_vm._s(_vm.$t('tryDifferent'))}})])]},proxy:true}])},'CyDataTableYdApi',{
      headers: _vm.headers,
      searchableFields: _vm.searchableFields,
    },false),[_c('template',{slot:"cy-table-cmp-header-append"},[_c('div',{staticClass:"pb-4"},[_c('span',{attrs:{"role":"heading"},domProps:{"innerHTML":_vm._s(_vm.$sanitizeHtml(_vm.getItemCountText()))}}),_c('CyMenu',{attrs:{"offset-y":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"ml-1",attrs:{"size":"16"}},on),[_vm._v(" text_snippet ")])]}}])},[_c('div',{staticClass:"snippet-menu pa-4"},[_c('div',{staticClass:"mb-4",domProps:{"innerHTML":_vm._s(_vm.$sanitizeHtml(_vm.$t('snippetMenuText')))}}),_c('CyCodeEditor',{staticClass:"snippet-menu__editor",attrs:{"value":_vm.generateWidgetConfig(),"read-only":true,"show-gutter":false,"action-btn-icon":"content_copy","action-btn-tooltip":_vm.$t('forms.copyToClipboard'),"code-lang":"yaml"},on:{"action-btn-clicked":_vm.copySnippet}})],1)])],1)])],2),_c('transition',{attrs:{"name":"slide-x-transition"}},[_c('CyInventoryResource',{directives:[{name:"show",rawName:"v-show",value:(_vm.sidePanelState.activeResourceId),expression:"sidePanelState.activeResourceId"},{name:"click-outside",rawName:"v-click-outside",value:({
        handler: _vm.closeSidePanel,
        include: _vm.getClickOutsideExceptions,
      }),expression:"{\n        handler: closeSidePanel,\n        include: getClickOutsideExceptions,\n      }"}],staticClass:"side-panel elevation-10 white rounded-lg overflow-hidden",attrs:{"resource":_vm.selectedResource,"loading":_vm._.isEmpty(_vm.selectedResource),"data-cy":"side-panel","aria-label":"Resource panel"},on:{"close":_vm.closeSidePanel,"deleted":_vm.onResourceDelete},model:{value:(_vm.sidePanelState.activeResourceTab),callback:function ($$v) {_vm.$set(_vm.sidePanelState, "activeResourceTab", $$v)},expression:"sidePanelState.activeResourceTab"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }