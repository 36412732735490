<template>
  <div class="d-flex flex-column justify-center height-100">
    <CyDataTableYdApi
      id="cy-inventory-table"
      ref="dataTable"
      class="inventory-table"
      :fetch-available="{ keyPath: 'inventoryResources' }"
      :row-click-callback="openSidePanel"
      :working-route="workingRoute"
      :searchable-fields="$static.searchableFields"
      :custom-search-placeholder="$t('inventory.filterByKeyword')"
      v-bind="{
        headers,
        searchableFields,
      }"
      elevation="0"
      key-field="id"
      data-cy="table">
      <template #table-cmp-header-filters>
        <div class="mx-4">
          <CyFilterTree
            :filter-tree="filters"
            :value="selectedFilters"
            @input="setFilterValues"/>
        </div>
      </template>
      <template #table-cmp-header-actions="{ paginate, filteredItemsTableData }">
        <CyDataTablePagination
          v-if="paginate && filteredItemsTableData.length"
          :items-length="filteredItemsTableData.length"
          :options.sync="dataTableProps"
          is-header
          class="top-pagination"
          data-cy="top-pagination"/>
        <CyButton
          v-if="quotasEnabled"
          v-has-rights-to="'CreateInventoryResource'"
          class="ml-4"
          icon="add"
          theme="secondary"
          variant="primary"
          :to="{
            name: 'newResource',
            params: { backRouteTo: 'inventory' },
          }"
          data-cy="create-resource-button">
          {{ $t('inventory.addResource') }}
        </CyButton>
      </template>
      <template #cy-table-cmp-header-tag="{ tag, remove }">
        <template v-if="_.values($static.FILTER_KEY_MATCHES).includes(tag.key.split('[')[0])"/>
        <template v-else>
          <CyTag
            class="ma-1"
            tall
            variant="warning">
            <v-icon
              size="20"
              class="mr-1"
              color="warning darken-3">
              data_object
            </v-icon>
            <b class="mr-2 warning--text text--darken-3">
              {{ tag.key.split('[')[0] }}:
            </b>
            <span class="warning--text text--darken-3">
              {{ tag.value }}
            </span>
            <a
              @click="remove(tag.key, tag.id)">
              <v-icon
                color="warning darken-3"
                size="16">
                close
              </v-icon>
            </a>
          </CyTag>
        </template>
      </template>
      <template slot="cy-table-cmp-header-append">
        <div class="pb-4">
          <span
            role="heading"
            v-html="$sanitizeHtml(getItemCountText())"/>
          <CyMenu
            offset-y
            :close-on-content-click="false">
            <template #activator="{ on }">
              <v-icon
                size="16"
                class="ml-1"
                v-on="on">
                text_snippet
              </v-icon>
            </template>
            <div class="snippet-menu pa-4">
              <div
                class="mb-4"
                v-html="$sanitizeHtml($t('snippetMenuText'))"/>
              <CyCodeEditor
                :value="generateWidgetConfig()"
                :read-only="true"
                :show-gutter="false"
                action-btn-icon="content_copy"
                :action-btn-tooltip="$t('forms.copyToClipboard')"
                code-lang="yaml"
                class="snippet-menu__editor"
                @action-btn-clicked="copySnippet"/>
              <!-- TODO: Add links to docs and inventory once the pages have been created -->
              <!-- <a
                class="cy-link"
                v-text="$t('prefilterMenu.prefiltersDocs')"/> -->
            </div>
          </CyMenu>
        </div>
      </template>
      <template #table-cmp-body-row="{ props: { item } }">
        <td key="name">
          <div class="d-flex align-center">
            <div class="line-height-xs">
              <CyInventoryResourceIcon
                :image-src="getImage(item)"
                :is-custom="isCustomResource(item)"
                class="mr-2"
                data-cy="item-icon"
                size="32"/>
            </div>
            <div>
              <div class="resource-row__name">
                <CyTooltip
                  open-delay="250"
                  top>
                  <template #activator="{ on }">
                    <span v-on="on">
                      {{ item.name }}
                    </span>
                  </template>
                  {{ item.name }}
                </CyTooltip>
              </div>
              <div class="resource-row__id">
                <CyTooltip
                  open-delay="250"
                  top>
                  <template #activator="{ on }">
                    <span v-on="on">
                      {{ getId(item) }}
                    </span>
                  </template>
                  {{ getId(item) }}
                </CyTooltip>
              </div>
            </div>
          </div>
        </td>
        <td
          key="provider"
          :title="getProviderTranslation(item, 'name')"
          data-cy="item-provider"
          v-text="getProviderTranslation(item) || getProvider(item)"/>
        <td key="category">
          <CyTag
            variant="default"
            data-cy="item-category">
            {{ item.category }}
          </CyTag>
        </td>
        <td
          key="type"
          data-cy="item-type">
          <div class="resource-row__type">
            <CyTooltip
              open-delay="250"
              top>
              <template #activator="{ on }">
                <span
                  v-on="on"
                  v-text="item.type"/>
              </template>
              {{ item.type }}
            </CyTooltip>
          </div>
        </td>
        <td
          key="project"
          data-cy="item-project"
          v-text="item.project_canonical"/>
        <td
          key="environment"
          data-cy="item-environment"
          v-text="item.environment_canonical"/>
      </template>
      <template #table-cmp-no-data>
        <div class="empty-state text-center">
          <div
            class="empty-state__heading h5"
            data-cy="empty-state-heading"
            v-text="$t('noResourcesFound')"/>
          <div
            class="text-body mt-2"
            data-cy="empty-state-subheading"
            v-text="$t('tryDifferent')"/>
        </div>
      </template>
    </CyDataTableYdApi>
    <transition name="slide-x-transition">
      <CyInventoryResource
        v-show="sidePanelState.activeResourceId"
        v-model="sidePanelState.activeResourceTab"
        v-click-outside="{
          handler: closeSidePanel,
          include: getClickOutsideExceptions,
        }"
        :resource="selectedResource"
        :loading="_.isEmpty(selectedResource)"
        class="side-panel elevation-10 white rounded-lg overflow-hidden"
        data-cy="side-panel"
        aria-label="Resource panel"
        @close="closeSidePanel"
        @deleted="onResourceDelete"/>
    </transition>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import CyCodeEditor from '@/components/code-editor.vue'
import CyDataTablePagination from '@/components/data-table/pagination.vue'
import CyDataTableYdApi from '@/components/data-table-yd-api.vue'
import CyFilterTree from '@/components/filter-tree.vue'
import CyInventoryResourceIcon from '@/components/inventory/resource-icon.vue'
import CyInventoryResource from '@/components/inventory/resource.vue'
import { FILTER_KEY_MATCHES, PREFILTER_KEY_MATCHES } from '@/utils/config/inventory-filters'
import { constructBreadcrumb } from '@/utils/helpers'
import * as yaml from 'js-yaml'
import 'brace/mode/yaml'

export default {
  name: 'CyPageInventory',
  breadcrumb () {
    return constructBreadcrumb(this.$options.name, this.$t('routes.inventory'), [
      {
        label: this.$t('routes.resourcesSection'),
        name: 'resourcesSection',
      },
    ])
  },
  header () {
    return {
      title: this.$t('routes.resourcesSection'),
      description: {
        text: this.$t('routes.resourcesSectionDescription'),
      },
    }
  },
  components: {
    CyDataTablePagination,
    CyDataTableYdApi,
    CyInventoryResource,
    CyInventoryResourceIcon,
    CyFilterTree,
    CyCodeEditor,
  },
  props: {
    requestedResourceId: {
      type: [Number, null],
      default: null,
    },
    requestedResourceTab: {
      type: String,
      default: '',
    },
  },
  data () {
    return {
      lastActiveResourceTab: null,
      sidePanelState: {
        activeResourceId: this.requestedResourceId,
        activeResourceTab: this.requestedResourceTab,
      },
      selectedSearchAttribute: 'all',
    }
  },
  computed: {
    ...mapState('organization', {
      cloudProviders: (state) => state.available.cloudProviders,
      filters: (state) => state.filters.inventoryResources,
      isResourceListLoading: (state) => state.fetchInProgress.inventoryResources,
      organization: (state) => state.detail,
    }),
    ...mapGetters('layout', [
      'getDataTableProps',
      'getDataTableFilters',
    ]),
    ...mapGetters('organization/inventory', [
      'getId',
      'getImage',
      'getProvider',
      'getProviderTranslation',
      'getResource',
      'isCustomResource',
    ]),
    $static () {
      return {
        attributes: [
          'name',
          'id',
          'provider',
          'category',
          'type',
          'project_canonical',
          'environment_canonical',
        ],
        searchableFields: [
          {
            name: 'name',
            label: this.$t('name'),
          },
          {
            name: 'type',
            label: this.$t('forms.type'),
          },
          {
            name: 'project_canonical',
            label: this.$t('Project'),
          },
          {
            name: 'environment_canonical',
            label: this.$t('Environment'),
          },
        ],
        FILTER_KEY_MATCHES,
        PREFILTER_KEY_MATCHES,
      }
    },
    quotasEnabled () {
      return this.organization.quotas
    },
    workingRoute () {
      return this.$router.resolve({ name: 'inventory' }).route
    },
    headers () {
      const headerAttributes = _.without(this.$static.attributes, 'id')
      return _.map(headerAttributes, (name) => ({
        text: this.$t(`inventory.values.attribute.${name}`),
        value: name,
        align: 'left',
      }))
    },
    searchableFields () {
      return _(this.$static.attributes)
        .filter((name) => [name, 'all'].includes(this.selectedSearchAttribute))
        .map((name) => ({
          label: this.$t(`inventory.values.attribute.${name}`),
          name,
        }))
        .value()
    },
    dataTableProps: {
      get () {
        return this.getDataTableProps('inventory')
      },
      set (props) {
        this.SET_DATA_TABLE_PROPS({
          name: 'inventory',
          props: { ...this.dataTableProps, ...props },
        })
      },
    },
    selectedResource () {
      return this.getResource(this.sidePanelState.activeResourceId)
    },
    selectedFilters () {
      const filters = this.tableFilters
      const standardFilters = _.omitBy(filters, (_value, key) => {
        const [category] = key.split('[')
        return !_.values(this.$static.FILTER_KEY_MATCHES).includes(category)
      })
      const categoryArrays = _.toPairs(standardFilters).map(([key, value]) => {
        const filterKey = _.invert(this.$static.FILTER_KEY_MATCHES)[key.split('[')[0]]
        return value.split(',').map((it) => `${filterKey}.${it}`)
      })
      return _.concat(...categoryArrays)
    },
    tableFilters () {
      return this.getDataTableFilters(this.workingRoute.name)
    },
  },
  watch: {
    sidePanelState: {
      async handler ({ activeResourceId, activeResourceTab }) {
        const oldQuery = _.transform(this.$route.query, (result, value, key) => {
          result[key] = (key === 'resourceId' && !_.isEmpty(value))
            ? Number(value)
            : value
        }, {})
        const newQuery = _.omit(oldQuery, ['resourceId', 'resourceTab'])
        if (activeResourceId) newQuery.resourceId = activeResourceId
        if (activeResourceId && activeResourceTab) newQuery.resourceTab = activeResourceTab
        if (!_.isEqual(newQuery, oldQuery)) {
          await this.$router.replace({ query: newQuery })
          this.$emit('queryUpdated', newQuery)
        }
      },
      deep: true,
    },
    isResourceListLoading: {
      handler (isLoading) {
        const id = this.requestedResourceId
        const isLoaded = !isLoading
        const isEmpty = _.isEmpty(this.selectedResource)
        if (id && isLoaded && isEmpty) {
          this.$once('queryUpdated', function (_newQuery) {
            this.SHOW_ALERT({
              type: 'error',
              content: this.$t('alerts.errors.inventoryResource.fetch', { id }),
            })
          })
          this.closeSidePanel()
        }
      },
    },
    tableFilters: {
      handler (newValue) {
        const queryString = _.isEmpty(newValue) ? '' : `?${new URLSearchParams(newValue).toString()}`
        const newURL = `${location.href.split('?')[0]}${queryString}`
        window.history.pushState('object', document.title, newURL)
      },
      deep: true,
    },
  },
  async created () {
    const { requestedResourceTab } = this
    const allowedTabs = ['details', 'json']
    this.getFiltersFromURL()

    if (_.isString(requestedResourceTab) && !allowedTabs.includes(requestedResourceTab)) {
      this.$set(this.sidePanelState, 'activeResourceTab', allowedTabs[0])
    }

    if (_.isEmpty(this.cloudProviders)) {
      await this.FETCH_AVAILABLE({
        keyPath: 'cloudProviders',
      })
    }
  },
  methods: {
    ...mapActions('alerts', [
      'SHOW_ALERT',
    ]),
    ...mapActions('organization', [
      'FETCH_AVAILABLE',
    ]),
    ...mapMutations('layout', [
      'SET_DATA_TABLE_PROPS',
      'SET_DATA_TABLE_FILTERS',
    ]),
    openSidePanel (item) {
      this.$set(this, 'sidePanelState', {
        activeResourceId: item.id,
        activeResourceTab: _.find([this.requestedResourceTab, this.lastActiveResourceTab, 'details']),
      })
    },
    closeSidePanel (_event) {
      this.lastActiveResourceTab = this.sidePanelState.activeResourceTab
      this.$set(this, 'sidePanelState', {
        activeResourceId: null,
        activeResourceTab: null,
      })
    },
    getClickOutsideExceptions () {
      const selectors = [
        '.cy-table-data__row',
        '.main-nav a',
        '.main-nav button',
        '.dev-locale-switcher__options',
        '.dev-layer',
        '.v-main__wrap > .v-toolbar a',
        '.v-main__wrap > .v-toolbar button',
        '.cy-pagination button',
        '.cy-menu-pagination',
        '.cy-tabs-container a',
        '.resource-menu .v-list-item',
        '.cy-alert__actions',
        '.resource-delete-dialog',
        '.v-overlay',
      ]
      return Array.from(document.querySelectorAll(selectors.join(', ')))
    },
    onResourceDelete () {
      this.closeSidePanel()
      this.$once('queryUpdated', async () => {
        this.$refs.dataTable.retrieveItems()
      })
    },
    setFilterValues (values) {
      const previousFilters = this.tableFilters
      const attributeFilters = _.pickBy(previousFilters, (_value, key) => {
        const [category] = key.split('[')
        return !_.values(this.$static.FILTER_KEY_MATCHES).includes(category)
      })
      const standardFilters = _.reduce(values, (acc, it) => {
        const [category, value] = it.split(/\.(.*)/s)
        const filterPrefix = this.$static.FILTER_KEY_MATCHES[category]
        if (!filterPrefix) return acc
        const filterKey = `${filterPrefix}[in]`
        if (!acc[filterKey]) acc[filterKey] = value
        else acc[filterKey] = `${acc[filterKey]},${value}`
        return acc
      }, {})
      const filters = { ...attributeFilters, ...standardFilters }
      this.SET_DATA_TABLE_FILTERS({ name: 'inventory', filters })
    },
    getFiltersFromURL () {
      const filters = Object.fromEntries(new URLSearchParams(window.location.search))
      this.SET_DATA_TABLE_FILTERS({ name: 'inventory', filters })
    },
    generateWidgetConfig () {
      const widgetConfig = _.toPairs(this.tableFilters).reduce((acc, [key, value]) => {
        const [category] = key.split('[')
        const filterName = _.invert(this.$static.FILTER_KEY_MATCHES)[category]
        const prefilterName = _.lowerCase(_.invert(this.$static.PREFILTER_KEY_MATCHES)[filterName])
        if (prefilterName) return { ...acc, [prefilterName]: value }
        if (!filterName) {
          const querySection = `${key}=${value}`
          const attributesString = acc.attributes ? `${acc.attributes}&${querySection}` : querySection
          return { ...acc, attributes: attributesString }
        }
        return acc
      }, {})
      const name = 'Resource widget example'
      const description = 'An example resource widget with query options'
      const configObject = {
        name,
        description,
        key: 'resource_widget_example',
        widget: 'cy_inventory_resource',
        type: 'string',
        widget_config: {
          attribute: 'id',
          ...(_.isEmpty(widgetConfig) ? {} : { filters: widgetConfig }),
        },
      }
      const unquotedYaml = yaml.dump(configObject)
      const quotedYaml = [name, description].reduce((output, string) => output.replace(string, `"${string}"`), unquotedYaml)
      return quotedYaml
    },
    copySnippet () {
      navigator.clipboard.writeText(this.generateWidgetConfig())
      this.SHOW_ALERT({ type: 'info', content: this.$t('snippetCopied') })
    },
    getItemCountText () {
      const currentItemCount = this.$refs.dataTable?.filteredItemsTableData?.length
      const { isResourceListLoading } = this
      if (isResourceListLoading) return ''
      return this.$tc('inventory.nItems', currentItemCount, { n: currentItemCount })
    },
  },
  i18n: {
    messages: {
      en: {
        title: '@:routes.inventory',
        attribute: '@:inventory.attribute',
        attributeLabel: '@.capitalize:attribute:',
        noResourcesFound: 'No resources found',
        snippetCopied: 'The resource configuration snippet has been copied to your clipboard',
        snippetMenuText: 'Copy the snippet below to generate a resource widget to use in a stack <code class="file-name">.forms.yml</code> file. This widget will list resources based on your current filters.',
        tryDifferent: 'Try different filters',
      },
      es: {
        title: '@:routes.inventory',
        attribute: '@:inventory.attribute',
        attributeLabel: '@.capitalize:attribute:',
        noResourcesFound: 'No se encontraron recursos',
        snippetCopied: 'El fragmento de configuración de recursos se ha copiado en su portapapeles',
        snippetMenuText: 'Copie el fragmento siguiente para generar un widget de recursos para usar en un archivo de pila <code class="file-name">.forms.yml</code>. Este widget enumerará los recursos según sus filtros actuales.',
        tryDifferent: 'Prueba diferentes filtros',
      },
      fr: {
        title: '@:routes.inventory',
        attribute: '@:inventory.attribute',
        attributeLabel: '@.capitalize:attribute:',
        noResourcesFound: 'Aucune ressource trouvée',
        snippetCopied: `L'extrait de configuration des ressources a été copié dans votre presse-papiers`,
        snippetMenuText: `Copiez l'extrait ci-dessous pour générer un widget de ressources à utiliser dans une configuration de stack <code class="file-name">.forms.yml</code>. Ce widget listera les ressources en fonction de vos filtres actuels.`,
        tryDifferent: 'Essayez différents filtres',
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.cy-table {
  &-cmp {
    display: flex;
    flex-direction: column;
    height: 100%;

    ::v-deep &-header {
      flex-grow: 0;
    }
  }

  ::v-deep &-data {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;

    &__row {
      cursor: pointer;
    }

    .v-data-table__wrapper {
      @extend %cy-scrollbars;
    }

    thead {
      position: sticky;
      z-index: 1;
      top: 0;
      background-color: cy-get-color("white");
    }
  }
}

.snippet-menu {
  width: 480px;

  &__editor {
    height: 150px;

    ::v-deep .ace_scroller {
      padding: 8px;
    }
  }

  ::v-deep .file-name {
    background-color: cy-get-color("grey", "light-2");
  }
}

.inventory-table {
  .resource-row {
    &__id {
      color: cy-get-color("primary", "light-2");
      font-size: $font-size-sm;
    }

    &__id,
    &__name,
    &__type {
      max-width: 155px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  ::v-deep .cy-table-cmp-header-searchbar {
    max-width: 280px;
  }

  ::v-deep .cy-table-cmp-header,
  ::v-deep .cy-table-cmp-header-tags {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  ::v-deep .cy-table-cmp-header,
  ::v-deep .cy-table-cmp-header-tags,
  ::v-deep .v-toolbar {
    background-color: transparent;
  }

  ::v-deep .v-data-footer {
    border-top: none;
  }

  ::v-deep .cy-table-data {
    background-color: transparent;

    thead {
      background-color: transparent;
    }

    &__row > td {
      border-bottom: none !important;
    }
  }
}

::v-deep .cy-table-data.is-empty {
  .v-data-table__wrapper,
  table {
    height: 100%;
  }
}

.empty-state {
  color: cy-get-color("primary", "light-3");
  line-height: $line-height-default;

  &__heading {
    color: cy-get-color("primary", "light-2");
    line-height: $line-height-sm;
  }
}

.search-filter {
  max-width: 190px;

  ::v-deep .v-input__prepend-inner {
    align-self: center;
    margin-top: 0;
  }
}

.search-attribute-filter-label {
  color: cy-get-color("grey", "dark-2");
}

.search-field {
  min-width: 258px;
}

.top-pagination {
  position: static;
  transform: none;
}

.side-panel {
  $offset: 8px;

  position: fixed;
  z-index: 110;
  top: $offset;
  right: $offset;
  width: 640px;
  height: calc(100% - #{$offset} * 2);
  transition: all 200ms ease-in;
}
</style>
